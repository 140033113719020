import { css } from "@emotion/react";
import { colors, spacing, lineHeights, fontSizes } from "../../../core/styles";
import { glassIcon, arrowIcon, loadingSpinner, jumpingArrow, iconClipper } from "./SearchDefault.style";

export const searchSquareMobile = {
  searchIconWrapper: () =>
    css({
      background: "transparent",
      border: "none",
      outline: "none",
      position: "absolute",
      padding: "13px 9px",
      path: {
        fill: colors.linkBlue.hex,
      },
      "&:disabled": {
        cursor: "unset",
        polyline: {
          fill: colors.disabledGrey.hex,
        },
      },
    }),
  form: () =>
    css({
      position: "relative",
    }),
  input: (hasError: boolean) =>
    css({
      textTransform: "uppercase",
      border: "unset",
      width: "80%",
      backgroundColor: colors.white.hex,
      padding: `${spacing.baseUnitMobile2}`,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.cat,
      color: hasError ? colors.red.hex : colors.headlineGrey.hex,

      "::placeholder": {
        color: colors.stdTextGrey.hex,
        textTransform: "none",
      },
      "&:focus": {
        outline: "none",
      },
    }),
  errorWrapper: () =>
    css({
      width: `calc(100% - 10px)`,
      margin: "auto",
      position: "absolute",
      right: 0,
      left: 0,
    }),
  placeholderStyles: () =>
    css({
      display: "none",
    }),
  iconClipper: iconClipper,
  jumpingArrow: jumpingArrow,
  glassIcon: glassIcon,
  arrowIcon: arrowIcon,
  loadingSpinner: loadingSpinner,
};
