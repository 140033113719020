import { css } from "@emotion/react";
import { vinDefault } from "./SearchDefault.style";

export const withoutContainer = {
  ...vinDefault,
  form: () =>
    css({
      maxWidth: "initial",
      margin: "auto",
      position: "relative",
    }),
};
